import { PricingTabWrapper } from "./styles"
import { FormProvider, useForm } from "react-hook-form"
import CustomSelect from "@Common/CustomSelect"
import CustomButton from "@Common/CustomButton"
import GathernChanel from "./components/GathernChanel"
import MultiChannel from "./components/MultiChannel"
import { useTranslation } from "react-i18next"
import { useAdvanceSettings } from "@services"
import { useContext, useEffect, useMemo, useState } from "react"
import { IProperty } from "../.."
import { ToastMessageContext } from "@context/toast-message-cotnext"
import dayjs from "dayjs"
import { useRecoilState, useSetRecoilState } from "recoil"
import { controleAdvancedSettings, reinitializeCalendar } from "@store/index"
import { LoadingWrapper } from '@components';
import { format } from 'date-fns';
import { Typography, Box } from "@mui/material"
import { useCategoryMangment } from "@services"
import { Grid } from "@mui/material"
import CustomDatePicker from "@Common/CustomDatePicker"
import MultiSelect from "@Common/MultiSelect"


interface IFormAirBnb {
	category: string
	listing_id: string
	rate: string
	date_from: string
	date_to: string
	units: IFormGathern
	mid: string
	thu: string
	fri: string
	sat: string
}
interface IFormGathern {
	mid: string
	thu: string
	fri: string
	sat: string
}
interface IProps {
	PropertyList: IProperty[]
	onClose?: () => void
	onSuccess?: () => void
	selectedProperyId?: string
	dateRange?: any
	selectedPrices?: any
}
const GatherinLabel = 'GatherIn'
const AirbnbLabel = 'Airbnb'
export default function PricingTab({ PropertyList, onClose = () => { }, onSuccess = () => { }, selectedProperyId, dateRange, selectedPrices }: IProps) {
	const controleAdvancedSettingsSetValue = useSetRecoilState(
		controleAdvancedSettings
	)
	const [reinitializeCalendarValue, reinitializeCalendarSetValue] =
		useRecoilState(reinitializeCalendar)
	/* ---------------------------------- Hooks --------------------------------- */
	const { updateAllChannelsPrice } = useAdvanceSettings()
	const { showError, showSuccess } = useContext(ToastMessageContext)

	const { mutate, isPending } = updateAllChannelsPrice({
		onSuccess: () => {
			showSuccess(t("CALENDAR.PRICE_UPDATE_SUCCESS"))
			onSuccess()
		},
		onError: () => showError(t("ERRORS.ERROR_500_MESSAGE")),
	})
	console.log('PropertyList', PropertyList)
	const { t } = useTranslation()
	const methods = useForm<IFormAirBnb>()
	const { watch, setValue, getValues } = methods
	const listing_idWatch = watch("listing_id")
	const [isCategorySelected, setIsCategorySelected] = useState(false)
	const [selectedProperties, setSelectedProperties] = useState<any>([])
	const [selectedChannels, setSelectedChannels] = useState<any>([])
	const [channelError, setChannelError] = useState(false)
	const airbnbUnitChannel = PropertyList?.filter(
		(item: any) => item.value == listing_idWatch
	)[0]?.airbnbUnit
	const gattherinUnitChannel = PropertyList?.filter(
		(item: any) => item.value == listing_idWatch
	)[0]?.gathrenUnit
	const isPropertyConnectedWithAirbnb = airbnbUnitChannel === "airbnbUnit"

	const isPropertyConnectedWithBooking = PropertyList?.filter(
		(item: any) => item?.value == listing_idWatch
	)[0]?.bookingUnitId?.length
	const gathrenUnitChannel = PropertyList?.filter(
		(item: any) => item.value == listing_idWatch
	)[0]?.gathrenUnit
	const isPorpertyConnectedWithGatherin = gathrenUnitChannel === "gathrenUnit"
	const onSubmit = async (value: IFormAirBnb) => {
		setChannelError(selectedChannels?.length == 0)
		if (selectedChannels?.length == 0) return
		const isUpdatingGatherin = value?.mid

		const date_from = !value?.date_from ? null : dayjs(value?.date_from)?.format("YYYY-MM-DD")
		const date_to = !value?.date_from ? null : dayjs(value?.date_to)?.format("YYYY-MM-DD")
		const rate = value?.date_from ? `${value.rate ?? 0}.00` : ""
		const booking = isPropertyConnectedWithBooking ? {
			date_from,
			date_to,
			rate,
		} : null
		const updateAirbnb = selectedChannels?.includes(AirbnbLabel) || selectedChannels?.includes(t('COMMON.All'))
		const updateGathrin = selectedChannels?.includes(GatherinLabel) || selectedChannels?.includes(t('COMMON.All'))
		let listOfSelected = []
		if (selectedProperties?.includes(t('COMMON.All'))){
			listOfSelected = PropertyList.map((x: any) => {
				return x?.value
			})
		} else {
			listOfSelected = selectedProperties.map((x: any) => {
				return PropertyList.find((xx: any) => xx.label == x)?.value
			})
		}

		const data = {

			propertiesId: value.category ? [] : listOfSelected,
			categoriesId: value.category ? [value.category] : [],
			airbnb: !updateAirbnb ? null : !value?.date_from ? null : {
				date_from,
				date_to,
				rate,
				"min_stay": 0
			},

			gathren: !updateGathrin ? null : !value?.mid ? null : {
				mid: Number(value?.mid || 0),
				thu: Number(value?.thu || 0),
				fri: Number(value?.fri || 0),
				sat: Number(value?.sat || 0),
			},
			booking,
		}

		mutate(data)
		controleAdvancedSettingsSetValue(false)
	}
	useEffect(() => {

		setValue('listing_id', String(selectedProperyId))
		let selectedName = PropertyList.find((x: any) => x.value == selectedProperyId)?.label
		setSelectedProperties([selectedName])
		setSelectedChannels([])
	}, [selectedProperyId])
	const connectedChannels = useMemo(() => {

		let chaanles: any = []
		let isAllSelected = selectedProperties?.includes(t('COMMON.All'))
		let base = isAllSelected ? PropertyList : selectedProperties
		base.map((xx: any, i: any) => {
			let current = PropertyList.find((x: any, ii: any) => {
				if (isAllSelected) {
					return i == ii
				}
				return x.label == xx
			})
			if (current?.gathrenUnit?.length) {
				if (!chaanles.includes(GatherinLabel)) {
					chaanles.push(GatherinLabel)
				}

			}
			if (current?.airbnbUnit?.length) {
				if (!chaanles.includes(AirbnbLabel)) {
					chaanles.push(AirbnbLabel)
				}


			}
		})
		return chaanles
	}, [selectedProperties, selectedProperties])
	useEffect(() => {
		const { mid, thu, fri, sat, rate } = selectedPrices
		mid && setValue('mid', String(mid))
		thu && setValue('thu', String(thu))
		fri && setValue('fri', String(fri))
		sat && setValue('sat', String(sat))
		rate && setValue('rate', String(rate))
	}, [selectedPrices])
	const { monthStartDate, monthEndDate } = dateRange
	useEffect(() => {

		if (monthStartDate) {
			setValue('date_from', format(new Date(monthStartDate as Date), "yyyy-MM-dd"))
		}
		if (monthEndDate) {
			setValue('date_to', format(new Date(monthEndDate as Date), "yyyy-MM-dd"))
		}
	}, [monthStartDate, monthEndDate])
	const { GetCategoriesQuery } = useCategoryMangment()
	const { data: GetCategories } = GetCategoriesQuery({
		pageNumber: '1',
		pageSize: '30',
		search: '',
	})
	const showNultiChannel = isPropertyConnectedWithAirbnb || isPropertyConnectedWithBooking ? true : false
	const showGatherinPricing = (selectedChannels.includes(t('COMMON.All')) && connectedChannels?.includes(GatherinLabel)) || selectedChannels.includes(GatherinLabel)
	const showAirbnbPricing = (selectedChannels.includes(t('COMMON.All')) && connectedChannels?.includes(AirbnbLabel)) || selectedChannels.includes(AirbnbLabel)
	return (
		<PricingTabWrapper>
			<LoadingWrapper loading={isPending} >

				<FormProvider {...methods}>
					<form onSubmit={methods.handleSubmit(onSubmit)}>
						<Grid item xs={12}>
							<Grid item xs={12}>
								<Typography sx={{
									color: '#261B6A',
									fontWeight: 500,
									fontSize: '20px',
									mb: 1
								}} >
									{t('COMMON.DATE')}
								</Typography>
							</Grid>
							<Box sx={{ display: 'flex' }} >
								<CustomDatePicker
									value={getValues('date_from')}
									name='date_from'
									label={t("GENERAL.FROM")}
									registerOptions={{
										required: {
											value: true,
											message: '',
										},
									}}
								/>
								<Box width={20} />
								<CustomDatePicker
									value={getValues('date_from')}
									name='date_to'
									label={t("CALENDAR.TO")}
									registerOptions={{
										required: {
											value: true,
											message: '',
										},
									}}
								/>
							</Box>

						</Grid>
						<div>


							{
								isCategorySelected ? <CustomSelect

									label="التنصيف"
									value={getValues('category')}
									name='category'
									registerOptions={{
										required: {
											value: true,
											message: t("COMMON.REQUIRED"),
										},
									}}
									data={GetCategories?.data?.result?.map((x: any) => {
										return {
											value: x?.categoryId,
											label: x?.categoryName,

										}
									}) ?? []}
								/> : <Box sx={{ mt: 1 }} >
									<MultiSelect
										label={t('COMMON.PROPERTY')}
										placeholder={t('COMMON.PROPERTY')}
										values={selectedProperties}
										setValues={(v: any) => setSelectedProperties(v)}
										data={PropertyList.length == 0 ? [] : [
											t('COMMON.All'),
											...PropertyList?.map((x: any) => x?.label)
										]} />
									<MultiSelect
										isError={channelError}
										placeholder={t('COMMON.Select_channel')}
										label={t('COMMON.CHANNEL')}
										values={selectedChannels}
										setValues={(v: any) => {
											setSelectedChannels(v)
											setChannelError(false)
										}}
										data={PropertyList.length == 0 ? [] : [
											t('COMMON.All'),
											...connectedChannels
										]} />
								</Box>
							}
							{showAirbnbPricing && <MultiChannel
								isConnectedWithBooking={false}
								isConnectedWithAirbnb={isPropertyConnectedWithAirbnb}
							/>}
							{showGatherinPricing && <GathernChanel />}


						</div>

						<div className='ContainerFooter'>
							<CustomButton label={t("SAVE")} withBG type='submit' />
							<CustomButton
								label={t("GENERAL.CANCEL_ALT")}
								onClick={() => {
									onClose()
									reinitializeCalendarSetValue(reinitializeCalendarValue + 1)
									controleAdvancedSettingsSetValue(false)
								}}
							/>
						</div>
					</form>
				</FormProvider>
			</LoadingWrapper>

		</PricingTabWrapper>
	)
}
